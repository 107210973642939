/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.icon-text {
  display: inline-flex;
  align-items: center;
}
.icon-text i {
  margin-top: 1px;
}
.icon-text i + span {
  margin-left: 4px;
}
.image {
  position: relative;
  margin: 0;
  background-color: #f5f5f5;
}
.image::before {
  content: '';
  display: block;
  padding-top: 100%;
}
.image figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0.75rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  padding: 4px;
}
.image img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image.is-contain img {
  object-fit: contain;
}
.message {
  padding: 1rem;
  line-height: 1.6;
  background-color: #f5f5f5;
}
.message.is-small {
  padding: 0.5rem;
  font-size: 12px;
}
.message.is-danger {
  background-color: #fff1f0;
  color: #ff4d4f;
}
.message.is-alert {
  color: #ff4d4f;
}
.ant-notification-notice.notification {
  background: #001529;
  color: #fff;
}
.ant-notification-notice.notification .ant-notification-notice-message {
  color: #fff;
}
.ant-notification-notice.notification .ant-notification-notice-close {
  color: #fff;
  opacity: 0.75;
}
.ant-notification-notice.notification .anticon.ant-notification-notice-icon-success {
  color: #68d4d2;
}
.page-loading {
  margin-top: 20vh;
  text-align: center;
}
.page-title {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}
.panel {
  padding: 1.5rem;
  background-color: #fafafa;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
}
.panel.is-narrow {
  padding: 1rem;
}
.picture-wall .picture-wall-current {
  margin-bottom: 1rem;
}
.picture-wall .picture-wall-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5rem;
  list-style: none;
  padding: 0;
}
.picture-wall .picture-wall-thumbnail {
  cursor: pointer;
}
.pill {
  display: inline-block;
  padding: 0 0.5rem;
  line-height: 2;
  font-size: 12px;
  border: 1px solid;
  border-radius: 99999px;
}
.pill.is-error {
  border-color: #ffa39e;
}
.pill.is-error [data-icon] {
  color: #ff4d4f;
}
.pill.is-success {
  border-color: #b7eb8f;
}
.pill.is-success [data-icon] {
  color: #73d13d;
}
.plain-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.plain-list li + li {
  margin-top: 0.75rem;
}
.m-1 {
  margin: 8px;
}
.mt-1 {
  margin-top: 8px;
}
.mb-1 {
  margin-bottom: 8px;
}
.mr-1 {
  margin-right: 8px;
}
.ml-1 {
  margin-left: 8px;
}
.m-2 {
  margin: 16px;
}
.mt-2 {
  margin-top: 16px;
}
.mb-2 {
  margin-bottom: 16px;
}
.mr-2 {
  margin-right: 16px;
}
.ml-2 {
  margin-left: 16px;
}
.m-3 {
  margin: 24px;
}
.mt-3 {
  margin-top: 24px;
}
.mb-3 {
  margin-bottom: 24px;
}
.mr-3 {
  margin-right: 24px;
}
.ml-3 {
  margin-left: 24px;
}
.m-4 {
  margin: 32px;
}
.mt-4 {
  margin-top: 32px;
}
.mb-4 {
  margin-bottom: 32px;
}
.mr-4 {
  margin-right: 32px;
}
.ml-4 {
  margin-left: 32px;
}
.m-5 {
  margin: 40px;
}
.mt-5 {
  margin-top: 40px;
}
.mb-5 {
  margin-bottom: 40px;
}
.mr-5 {
  margin-right: 40px;
}
.ml-5 {
  margin-left: 40px;
}
.m-6 {
  margin: 48px;
}
.mt-6 {
  margin-top: 48px;
}
.mb-6 {
  margin-bottom: 48px;
}
.mr-6 {
  margin-right: 48px;
}
.ml-6 {
  margin-left: 48px;
}
.text-sm {
  font-size: 12px;
}
.text-base {
  font-size: 14px;
}
.text-md {
  font-size: 16px;
}
.text-lg {
  font-size: 18px;
}
.text-xl {
  font-size: 20px;
}
.text-bold {
  font-weight: bold;
}
.text-primary {
  color: #019FAD;
}
.text-blue {
  color: #40a9ff;
}
.text-magenta {
  color: #f759ab;
}
.text-purple {
  color: #b37feb;
}
.text-red {
  color: #ff4d4f;
}
.text-gold {
  color: #faad14;
}
.text-geekblue {
  color: #2f54eb;
}
.text-mute {
  color: #bfbfbf;
}
.text-header {
  color: rgba(0, 0, 0, 0.85);
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-paragraph {
  line-height: 1.8;
}
.tag {
  display: inline-block;
  font-size: 12px;
  line-height: 2;
  padding: 0 0.5rem;
}
.tag.is-primary {
  background-color: #d5edeb;
  color: #005261;
}
.tag.is-danger {
  background-color: #ffd6e7;
  color: #9e1068;
}
.tag.is-volcano {
  background-color: #ffd8bf;
  color: #ad2102;
}
.tag.is-gold {
  background-color: #fff1b8;
  color: #ad6800;
}
.tag.is-info {
  background-color: #bae7ff;
  color: #0050b3;
}
.tag.is-mute {
  background-color: #e8e8e8;
  color: #595959;
}
.tag + .tag {
  margin-left: 0.5rem;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.main {
  padding: 24px;
  background: #fff;
}
.container {
  margin: 0 auto;
  max-width: 1008px;
}
.login {
  margin: 0 auto;
  padding-top: 20vh;
  max-width: 25rem;
  min-height: 100vh;
  width: 100%;
}
.password-change-mail-input {
  margin: 0 auto;
  padding-top: 20vh;
  max-width: 30rem;
  min-height: 100vh;
  width: 100%;
}
.mail-address-change-input {
  margin: 0 auto;
  padding-top: 20vh;
  max-width: 35rem;
  min-height: 100vh;
  width: 100%;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
  position: relative;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected::before,
.ant-menu.ant-menu-dark .ant-menu-item-selected::before {
  content: '';
  display: block;
  background: #019FAD;
  width: 6px;
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 0;
}
.ant-modal.modal-long .ant-modal-content {
  display: flex;
  flex-direction: column;
  max-height: 85vh;
}
.ant-modal.modal-long .ant-modal-content .ant-modal-header {
  flex: 0 0 auto;
  border-bottom: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.ant-modal.modal-long .ant-modal-content .ant-modal-body {
  flex: 1 1 auto;
  overflow: scroll;
}
.ant-modal.modal-long .ant-modal-content .ant-modal-footer {
  flex: 0 0 auto;
  border-top: 0;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
}
#root .ant-collapse-borderless {
  background-color: #fafafa;
}
#root .ant-table-thead,
#root .ant-table-body {
  font-size: 12px;
}
#root .ant-table-thead .ant-checkbox-wrapper,
#root .ant-table-body .ant-checkbox-wrapper {
  font-size: 12px;
}
#root .ant-table-thead .min-cell,
#root .ant-table-body .min-cell {
  width: 1px;
  white-space: nowrap;
}
#root .ant-table-tbody tr:hover > td:not(.success-cell):not(.warning-cell):not(.error-cell) {
  background: transparent !important;
}
#root .ant-table-tbody .success-cell {
  background-color: #f6ffed;
}
#root .ant-table-tbody .warning-cell {
  background-color: #fffbe6;
}
#root .ant-table-tbody .error-cell {
  background-color: #fff1f0;
}
#root .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background-color: #fafafa;
}
.ant-checkbox-inner {
  border: 1.5px solid #019FAD !important;
}
.ant-checkbox:hover .ant-checkbox-inner {
  border: 1.5px solid #019FAD !important;
}
.stock-update-btn {
  padding: 0 4px;
  height: 80%;
  font-size: 10px !important;
}
.stock-update-btn:hover,
.stock-update-btn:focus {
  padding: 0 4px;
  height: 80%;
  font-size: 10px !important;
}
